import React from "react";

const Products = () => {
  return (
    <div className="mt-4 w-[100%] flex flex-col items-center">
      <h1 className="text-4xl font-bold underline text-center my-10 flex flex-col">
        Products
      </h1>
      <div className="flex flex-col lg:flex-row sm:max-md:w-[70%]  items-center lg:w-[100%]">
        <img
          className="sm:max-md:w-full lg:w-1/3 p-8"
          src={require("../assets/images/dummyProduct1.png")}
          alt=""
        />
        <img
          className="sm:max-md:w-full lg:w-1/3 p-8"
          src={require("../assets/images/dummyProduct2.png")}
          alt=""
        />
        <img
          className="sm:max-md:w-full lg:w-1/3 p-8"
          src={require("../assets/images/dummyProduct3.png")}
          alt=""
        />
      </div>
      <div className="mb-16 flex flex-col text-center">
        <p className="text-xl mb-3">
          We are coming with loads of products with
        </p>
        <p className="text-3xl mb-3">"Homemade Goodness in every Jar"</p>
        <p className="text-xl">Stay Tuned!!!</p>
      </div>
    </div>
  );
};

export default Products;
