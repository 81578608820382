import React from "react";

const ContactUs = () => {
  return (
    <div className="min-h-[500px] bg-white py-4">
      <div className="text-center mt-20">
        <p className="text-2xl mb-5">
          We will be delighted to hear from you...
        </p>
        <p className="underline">Address:</p>
        <p>
          H. No. 53, R Green Residency, Chipiyana Buzurg, Greater Noida, GBN,
          Uttar Pradesh - 201009
        </p>
        <p className="underline">E-mail:</p>

        <p>jaimaakamaksha2024@gmail.com</p>
      </div>
    </div>
  );
};

export default ContactUs;
