import React from "react";
import HomeBanner from "./HomeBanner";
import AboutUs from "./AboutUs";
import Products from "./Products";

const Body = () => {
  return (
    <div className="w-[100%] min-h-[calc(100vh-8rem) text-[#3E6148]">
      <HomeBanner />
      <AboutUs />
      <Products />
    </div>
  );
};

export default Body;
